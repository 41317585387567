import React from 'react'
import { useSelector } from 'react-redux'
import { MoblieAdditionalOutputTabs } from 'components/audio-pipeline/TranscriptPageTabs/MobileAdditionalOuputTabs/constants'
import { GridBreakpointDimensions, GridBreakpoints } from 'redux/reducers/layout/types'
import { getBreakPoint } from 'redux/selectors/layout' 
import { SummarisedText } from 'components/audio-pipeline/SummarisedText'
import { Headline } from 'components/audio-pipeline/Headline'
import { ArticleGeneration } from 'components/audio-pipeline/ArticleGeneration'
import { SEOTags } from 'components/audio-pipeline/SEOTags'
import styles from './styles.module.css'

export interface Props {
  activeTab?: string
}

export const TranscriptAdditionalContent: React.FC<Props> = ({ activeTab }) => {
  const breakpoint = useSelector(getBreakPoint)
  const isMobile = GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.sm]
  
  if (isMobile && activeTab) {
    switch (activeTab) {
      case MoblieAdditionalOutputTabs.Summary:
        return <SummarisedText isMobile={true}/>
      case MoblieAdditionalOutputTabs.Headline:
        return <Headline isMobile={true}/>
      case MoblieAdditionalOutputTabs.SEOTags:
        return <SEOTags isMobile={true}/>
      case MoblieAdditionalOutputTabs.ArticleGeneration:
        return <ArticleGeneration isMobile={true} />
      default:
        return <SummarisedText />
    }
  }
  
  return (
    <div className={styles.wrapper}>
      <SummarisedText />
      <Headline />
      <SEOTags />
      <ArticleGeneration />
    </div>
  )
}