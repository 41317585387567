import React from 'react'
import styles from './styles.module.css'

export interface Props {
  name?: string | null
  startTimeText: string
}

export const MobileParagraph: React.FC<Props> = ({ name, startTimeText }) => (
  <>
    {name && (
      <div className={styles.wrapper}>
        <span className={styles.speaker}>{name}</span>
        <span className={styles.timestamp}>{startTimeText}</span>
      </div>
    )}
  </>
)