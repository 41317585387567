import React from 'react'
import { Dropdown } from 'antd'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { IconButton } from 'components/common/general/IconButton'
import { MENU_ITEMS_TRIGGER, getMenuItems } from './menuItems'
import styles from './styles.module.css'

export const MenuInfo: React.FC = () => {
  const menuItems = getMenuItems()

  return (
    <div className={styles.wrapper}>
      <Dropdown
        menu={{ items: menuItems }}
        trigger={MENU_ITEMS_TRIGGER}
        overlayClassName={styles.dropdown}
      >
        <IconButton
          iconType={CustomIconType.Menu}
          className={styles.button}
        />
      </Dropdown>
    </div>
  )
}