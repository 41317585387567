import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import {
  ScrollIntoViewVerticalAlignment,
  TranscriptionSpeakerParagraph
} from 'components/audio-pipeline/TranscriptContent/types'
import { WINDOW_INNER_HEIGHT_THRESHOLD } from 'components/audio-pipeline/TranscriptContent/constants'
import { Card } from 'components/common/data-display/Card'
import { SpeakerParagraph } from 'components/audio-pipeline/TranscriptContent/TranscriptParagraph'
import {
  getEditedWordId,
  getEditedWords,
  getPlayerCurrentWordId,
  getSelectedTranscriptActionTab,
  getTranscriptionParagraphs
} from 'redux/selectors/audio-pipeline'
import {
  getSearchSelectedMatchGroupWordIds,
  getSearchSelectedMatchParagraphIndex,
  getTranscriptionSearchMatchedWordIds
} from 'redux/selectors/transcript-search'
import { GridBreakpointDimensions, GridBreakpoints } from 'redux/reducers/layout/types'
import { RootState } from 'redux/types/store'
import { getParagraphPadding } from 'utils/common/padding'
import isNull from 'lodash/isNull'
import styles from './styles.module.css'

export const TranscriptContent: React.FC = React.memo(() => {
  const data = useSelector(getTranscriptionParagraphs)
  const searchMatchParagraphIndex = useSelector(getSearchSelectedMatchParagraphIndex)
  const [scrollToIndex, setScrollToIndex] = useState<number | null>(null)
  const searchMatchedWordIds = useSelector(getTranscriptionSearchMatchedWordIds)
  const editedWordId = useSelector(getEditedWordId)
  const currentWordId = useSelector(getPlayerCurrentWordId)
  const editedWordsById = useSelector(getEditedWords)
  const selectedSearchGroupWords = useSelector(getSearchSelectedMatchGroupWordIds)
  const activeActionTab = useSelector(getSelectedTranscriptActionTab)

  const breakpoint = useSelector((state: RootState) => state.layout.breakpoint)
  const isMobile = GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.sm]

  const handleParagraphActive = useCallback((index: number) => {
    if (!searchMatchParagraphIndex) {
      setScrollToIndex(index)
    }
  }, [searchMatchParagraphIndex])

  useEffect(() => {
    if (!isNull(searchMatchParagraphIndex)) {
      return setScrollToIndex(searchMatchParagraphIndex)
    }
  }, [searchMatchParagraphIndex])

  useEffect(() => {
    const paragraphNode = document.getElementById(`paragraph-${scrollToIndex}`)
    const windowHeight = window.innerHeight

    const block = windowHeight > WINDOW_INNER_HEIGHT_THRESHOLD
      ? ScrollIntoViewVerticalAlignment.Center
      : ScrollIntoViewVerticalAlignment.End

    if (paragraphNode) {
      paragraphNode.scrollIntoView({ block })
    }

  }, [scrollToIndex])

  const renderParagraph = useCallback(({
    speaker,
    startTime,
    endTime,
    wordTimestamps
  }: TranscriptionSpeakerParagraph, index: number) => {
    return (
      <div
        id={`paragraph-${index}`}
        key={`${startTime}-${endTime}`}
      >
        <SpeakerParagraph
          index={index}
          speaker={speaker}
          startTime={startTime}
          endTime={endTime}
          words={wordTimestamps}
          className={classNames({
            [styles.last]: index === data.length - 1
          })}
          searchMatchedWordIds={searchMatchedWordIds}
          selectedSearchGroupWords={selectedSearchGroupWords}
          editedWordId={editedWordId}
          currentWordId={currentWordId}
          editedWordsById={editedWordsById}
          onParagraphActive={handleParagraphActive}
        />
      </div>
    )
  }, [
    data,
    searchMatchedWordIds,
    selectedSearchGroupWords,
    editedWordId,
    currentWordId,
    editedWordsById,
    handleParagraphActive
  ])

  const getPaddingTop = () => getParagraphPadding(isMobile, activeActionTab)

  return (
    <Card
      shouldRenderNoDataLabel={!data.length}
      className={classNames(
        styles.wrapper, {
          [styles.tabSelected]: !!activeActionTab
        })}>
      <div 
        className={styles.transcriptWrapper} 
        style={{ paddingTop: getPaddingTop() }}>
        {data.map(renderParagraph)}
      </div>
    </Card>
  )
})