import { intl } from 'utils/common/intl'
import { TabsProps } from 'antd'

export enum MoblieAdditionalOutputTabs {
  Summary = 'summary',
  Headline = 'headline',
  SEOTags = 'seo-tags',
  ArticleGeneration = 'article-generation',
}

export const TAB_ITEMS: TabsProps['items'] = [
  {
    key: MoblieAdditionalOutputTabs.Summary,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.summarised-text.title'
    })
  },
  {
    key: MoblieAdditionalOutputTabs.Headline,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.headline.title'
    })
  },
  {
    key: MoblieAdditionalOutputTabs.SEOTags,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.seo-tags.title'
    })
  },
  {
    key: MoblieAdditionalOutputTabs.ArticleGeneration,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.article-generation.title'
    })
  }
]