import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { formatPlaybackWordTime } from 'utils/audio-pipeline/audio-player'
import { TranscriptionTableCellValue } from 'components/audio-pipeline/Transcriptions/DefaultTableCellValue'
import { TranscriptionLibraryItemDataKeys } from 'redux/reducers/transcription-library/types'
import { formatMobileDate } from 'utils/common/date'
import styles from './styles.module.css'

interface Props {
  duration?: number | null
  createdAt?: string
  disabled?: boolean
}

export const TextContent: React.FC<Props> = ({ createdAt, duration, disabled }) => {
  const { formatMessage } = useIntl()
  
  const formatDate = useCallback(({ value }: { value: Date | string | number }) => {
    return formatMobileDate(value)
  }, [])

  const formatDuration = useCallback(({ value }: { id: string, value: number | null }) => {
    const hasNoDuration = value === 0 || value === null
    if (hasNoDuration) {
      return formatMessage({
        id: 'component.transcription-library.table.column.no-data.label'
      })
    }
    return formatPlaybackWordTime(value)
  }, [formatMessage])

  return (
    <div className={styles.wrapper}>
      <TranscriptionTableCellValue
        id={TranscriptionLibraryItemDataKeys.CreatedAt}
        value={createdAt}
        disabled={disabled}
        formatter={formatDate}
        className={styles.dateAndDuration}
      />
      <span className={styles.separator}> · </span>
      <TranscriptionTableCellValue
        id={TranscriptionLibraryItemDataKeys.Duration}
        value={duration}
        disabled={disabled}
        formatter={formatDuration}
        className={styles.dateAndDuration}
      />
    </div>
  )
}