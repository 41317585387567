import { Typography } from 'antd'

import styles from './styles.module.css'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { useIntl } from 'react-intl'

const { Text } = Typography

export const DropZoneMessage = () => {
  const { formatMessage } = useIntl()

  const message = formatMessage({
    id: 'component.audio-pipeline.file-upload.drop-zone.message.text',
  })

  return (
    <div className={styles.container}>
      <CustomIcon type={CustomIconType.AlertIcon} className={styles.icon} />
      <Text className={styles.message}>{message}</Text>
    </div>
  )
}
