import React, { useCallback, useEffect } from 'react'
import { Audio } from 'components/common/media/Audio'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTranscriptAudioUrl,
  setPlayerCurrentTime,
  startPlayback,
  stopPlayback,
} from 'redux/actions/audio-pipeline'
import {
  getIsPlaybackStarted,
  getPlayerCurrentWordId,
  getTranscriptAudioUrl,
} from 'redux/selectors/audio-pipeline'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import config from 'config'

interface Props {
  id?: string
}

export const AudioPlayer: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch()
  const currentWordId = useSelector(getPlayerCurrentWordId)
  const isPlaybackStarted = useSelector(getIsPlaybackStarted)
  const transcriptAudioUrl = useSelector(getTranscriptAudioUrl)
  const startTime = currentWordId && currentWordId.split('-')[1]
  const time = Number(startTime) / 1000
  const audioSrc = transcriptAudioUrl

  const handleTimeUpdate = useCallback(
    (timestamp: number) => {
      if (!isPlaybackStarted) {
        dispatch(setPlayerCurrentTime(timestamp))
      }
    },
    [dispatch, isPlaybackStarted]
  )

  const handlePlay = useCallback(() => {
    dispatch(startPlayback())

    trackUserInteraction({
      interactionTarget: DataLayerTrackingEvents.ButtonClick,
      interactionMethod: ObserveEventInteractionMethod.Click,
      targetMetaData: {
        elementName: config.trackIds.playPlayback,
        elementValue: config.trackIds.playPlayback,
      },
    })
  }, [dispatch])

  const handlePause = useCallback(() => {
    dispatch(stopPlayback())

    trackUserInteraction({
      interactionTarget: DataLayerTrackingEvents.ButtonClick,
      interactionMethod: ObserveEventInteractionMethod.Click,
      targetMetaData: {
        elementName: config.trackIds.pausePlayback,
        elementValue: config.trackIds.pausePlayback,
      },
    })
  }, [dispatch])

  const handleError = useCallback(() => {
    if (id) {
      dispatch(fetchTranscriptAudioUrl(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    // Reset playback when the selected file is changed
    dispatch(setPlayerCurrentTime(0))
    handlePause()
  }, [dispatch, handlePause, transcriptAudioUrl])

  return (
    <Audio
      id={id}
      src={audioSrc || ''}
      currentTime={time}
      onPlay={handlePlay}
      onPause={handlePause}
      onError={handleError}
      onTimeUpdate={handleTimeUpdate}
    />
  )
}
