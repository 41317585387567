import React from 'react'
import styles from './styles.module.css'

export interface Props {
  title: string,
  isMobile: boolean
}

export const SearchTitle: React.FC<Props> = ({ title, isMobile }) => (
  <>
    {isMobile && (
      <div className={styles.wrapper}>
        {title}
      </div>
    )}
  </>
)