import React from 'react'
import { OptionsButton } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/MobileOptionsBar/OptionsButton'
import {
  CopyButton
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/CopyButton'
import { TranscriptActionOptions } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/types'

interface Props {
  activeTab?: TranscriptActionOptions | null
  text?: string | null
}

export const MobileOptionsBar: React.FC<Props> = ({ activeTab, text }) => {
  if (activeTab) {
    return null
  }
  
  return (
    <>
      <OptionsButton />
      {text && <CopyButton />}
    </>
  )
}