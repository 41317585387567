import React from 'react'
import { useIntl } from 'react-intl'
import { TranscriptionTableCellValue } from 'components/audio-pipeline/Transcriptions/DefaultTableCellValue'
import {
  TranscriptionLibraryItemDataKeys,
  TranscriptionStatus,
} from 'redux/reducers/transcription-library/types'
import { TextContent } from './TextContent'
import { StatusAndActions } from './StatusAndActions'
import styles from './styles.module.css'

interface Props {
  id: string
  status?: TranscriptionStatus
  expiryAfterDays?: number | null
  duration?: number | null
  createdAt?: string
  name: string
}

export const MobileContent: React.FC<Props> = ({
  id,
  status,
  expiryAfterDays,
  duration,
  createdAt,
  name,
}) => {
  const { formatMessage } = useIntl()
  const disabled = status === TranscriptionStatus.Transcribing

  const statusLabel =
    status === TranscriptionStatus.Expiring
      ? formatMessage(
          {
            id: `component.transcription-library.transcription.status.expiring`,
          },
          { days: expiryAfterDays }
        )
      : null

  return (
    <div className={styles.wrapper}>
      <TranscriptionTableCellValue
        id={TranscriptionLibraryItemDataKeys.Name}
        value={name}
        disabled={disabled}
        className={styles.name}
      />
      <div className={styles.mobileSecondaryRow}>
        <TextContent duration={duration} createdAt={createdAt} disabled={disabled} />
        <StatusAndActions status={status} label={statusLabel} rowId={id} disabled={disabled} />
      </div>
    </div>
  )
}
