import { DataLayerPageViewPayload } from 'utils/tracking/types'

export enum TrackingEvents {
  FormSubmit = 'gtm.formSubmit',
  DndFileUpload = 'gtm.dndFileUpload',
}

export enum DataLayerTrackingEvents {
  ButtonClick = 'button-click',
  NavigationSelection = 'navigation-selection',
  DropdownSelection = 'drop-down-selection',
}

export enum EventTypes {
  UserInteraction = 'user interaction',
  BrowserAction = 'browser action',
  MediaAction = 'media action',
  Error = 'error',
  RegistrationAction = 'registration action',
  SubscriptionAction = 'subscription action',
  InternalSearch = 'internal search',
  PollWidget = 'poll widget',
  GoogleExtendedAccess = 'google extended access',
}

export enum ObserveEventSchemaId {
  Event = 'event',
}

export enum ObserveEventInteractionMethod {
  Click = 'click',
  Scroll = 'scroll',
  Automated = 'automated',
  Swipe = 'swipe',
  MouseMove = 'mouse move',
  PageLoad = 'page load',
  EnteredViewport = 'entered viewport',
  FormSubmit = 'form submit',
  VideoPlay = 'video play',
  Impression = 'impression',
  Gesture = 'gesture',
}

export const DEFAULT_DATA_LAYER_PAGE_VIEW_PAYLOAD: DataLayerPageViewPayload = {
  meta: {
    siteName: 'Transcribe',
    siteRegion: 'uk',
  },
  page: {
    pageViewId: window.crypto.randomUUID(),
    pageName: '',
    section: '',
    section2: '',
    pageType: 'newsukproduct',
  },
  user: {
    visitorId: 'N/A',
    department: '',
    restrictions: '',
  },
}
