import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { Modal } from 'antd'
import { ModalIds } from 'redux/reducers/modals/types'
import {
  cancelDeleteTranscription,
  confirmDeleteTranscription,
} from 'redux/actions/transcription-library'
import { getOpenModalIds } from 'redux/selectors/modals'
import { getIsLoading } from 'redux/selectors/loaders'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { GridBreakpointDimensions, GridBreakpoints } from 'redux/reducers/layout/types'
import styles from './styles.module.css'
import { ModalTitle } from 'components/audio-pipeline/Transcriptions/Modals/Title'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import config from 'config'

export const Delete: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const breakpoint = useSelector((state: RootState) => state.layout.breakpoint)

  const isMobile =
    GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.sm]
  
  const mobileTitle = formatMessage({ id: 'component.transcription-library.delete.modal.mobile.title' })
  const title = formatMessage({ id: 'component.transcription-library.delete.modal.title' })
  const confirm = formatMessage({ id: 'component.transcription-library.delete.modal.confirm' })
  const cancel = formatMessage({ id: 'component.transcription-library.delete.modal.cancel' })
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.DeleteTranscription)
  )

  const responsiveTitle = useMemo(() => 
    isMobile ? mobileTitle : title,
  [isMobile, title])

  const openModalIds = useSelector(getOpenModalIds)
  const isModalOpen = openModalIds.includes(ModalIds.DeleteTranscription)

  const okButtonProps = useMemo(
    () => ({
      loading: isLoading,
    }),
    [isLoading]
  )

  const cancelButtonProps = useMemo(
    () => ({
      disabled: isLoading,
    }),
    [isLoading]
  )

  const handleConfirm = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      dispatch(confirmDeleteTranscription())

      trackUserInteraction({
        interactionTarget: DataLayerTrackingEvents.ButtonClick,
        interactionMethod: ObserveEventInteractionMethod.Click,
        targetMetaData: {
          elementName: config.trackIds.deleteTranscription,
          elementValue: config.trackIds.deleteTranscription,
        },
      })
    },
    [dispatch]
  )

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      dispatch(cancelDeleteTranscription())
    },
    [dispatch]
  )

  return (
    <Modal
      open={isModalOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okText={confirm}
      cancelText={cancel}
      className={styles.modalContainer}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      title={<ModalTitle text={responsiveTitle} />}
      closeIcon={<CustomIcon type={CustomIconType.Close} />}
      centered
      transitionName="ant-fade"
    />
  )
}
