import { RootState } from 'redux/types/store'
import { createSelector } from 'reselect'
import { addDays, differenceInDays, getTime } from 'date-fns'
import {
  TranscriptionLibraryItem,
  TranscriptionLibraryItemDataKeys,
  TranscriptionStatus,
} from 'redux/reducers/transcription-library/types'
import { TranscriptionLibraryListItem } from 'components/audio-pipeline/Transcriptions/types'
import {
  TRANSCRIPTION_EXPIRING_DAYS_LEFT_THRESHOLD,
  TRANSCRIPTION_EXPIRING_DAYS_THRESHOLD,
} from 'redux/reducers/transcription-library/constants'
import { getIsLoading } from 'redux/selectors/loaders'
import { Loaders } from 'redux/reducers/loaders/types'
import { orderBy } from 'lodash'
import { TranscriptFilterTabs } from 'redux/actions/transcription-library/types'
import { TRANSCRIPT_FILTER_TAB_ITEMS } from 'redux/selectors/transcription-library/constants'

export const getTranscriptionLibraryItemsById = (state: RootState) =>
  state.transcriptionLibrary.itemsById

export const getTranscriptionLibraryEditedItemId = (state: RootState) =>
  state.transcriptionLibrary.editedItemId

export const getTranscriptionActiveFilter = (state: RootState) =>
  state.transcriptionLibrary.activeFilter

export const getTranscriptionLibraryItemById = createSelector(
  getTranscriptionLibraryItemsById,
  (_: RootState, id: string) => id,
  (itemsById, id) => itemsById[id]
)

export const getTranscriptionLibraryItems = createSelector(
  getTranscriptionLibraryItemsById,
  (itemsById) => Object.values(itemsById)
)

export const getTranscriptionLibraryEditedItemName = createSelector(
  getTranscriptionLibraryEditedItemId,
  getTranscriptionLibraryItemsById,
  (id, itemsById) => {
    const item: TranscriptionLibraryItem | null = id ? itemsById[id] : null

    return item?.name || ''
  }
)

export const getAllTranscripts = createSelector(
  getTranscriptionLibraryItems,
  (items: any[]): TranscriptionLibraryListItem[] => {
    const mappedItems = items.map(({ createdAt, status, ...rest }) => {
      const createdAtTs = getTime(new Date(createdAt))
      const expireAtTs = getTime(addDays(createdAtTs, TRANSCRIPTION_EXPIRING_DAYS_THRESHOLD))
      const expiryAfterDays =
        createdAtTs && expireAtTs ? differenceInDays(expireAtTs, Date.now()) : null
      const currentStatus =
        status === TranscriptionStatus.Transcribed &&
        expiryAfterDays &&
        expiryAfterDays <= TRANSCRIPTION_EXPIRING_DAYS_LEFT_THRESHOLD
          ? TranscriptionStatus.Expiring
          : status

      return {
        ...rest,
        createdAt: createdAtTs,
        expireAt: expireAtTs,
        status: currentStatus,
        expiryAfterDays,
      }
    })

    return orderBy(mappedItems, TranscriptionLibraryItemDataKeys.CreatedAt, 'desc')
  }
)

export const getTotalExpiringTranscriptions = createSelector(
  getAllTranscripts,
  (allTranscripts) =>
    allTranscripts.filter(({ status }) => status === TranscriptionStatus.Expiring).length
)

export const getTranscribeListTabs = createSelector(
  getTotalExpiringTranscriptions,
  (totalExpiringTranscriptions) =>
    TRANSCRIPT_FILTER_TAB_ITEMS!.map((item) => {
      if (item.key === TranscriptFilterTabs.Expiring) {
        return {
          ...item,
          label: `${item.label} (${totalExpiringTranscriptions})`,
        }
      }

      return item
    })
)

export const getTranscriptionLibraryListItemsByFilter = createSelector(
  getTranscriptionActiveFilter,
  getAllTranscripts,
  (activeFilter, allTranscripts): TranscriptionLibraryListItem[] => {
    if (activeFilter === TranscriptFilterTabs.Expiring) {
      return allTranscripts.filter(({ status }) => status === TranscriptionStatus.Expiring)
    }

    return allTranscripts
  }
)

export const getPendingTranscriptionLibraryItems = createSelector(
  getTranscriptionLibraryItems,
  (items) => items.filter(({ status }) => status === TranscriptionStatus.Transcribing)
)

export const getPendingTranscriptionLibraryItemIds = createSelector(
  getPendingTranscriptionLibraryItems,
  (items: TranscriptionLibraryItem[]) => items.map(({ id }) => id)
)

export const getIsPollingTranscriptions = (state: RootState) =>
  getIsLoading(state, Loaders.TranscriptionsPolling)
