const messages = {
  'component.transcription-library.transcription.status.transcribing': 'Transcribing',
  'component.transcription-library.transcription.status.transcribed': 'Transcribed',
  'component.transcription-library.transcription.status.failed': 'Failed',
  'component.transcription-library.transcription.status.expiring': `Expiring in {days} days`,
  'component.transcription-library.table.column.name': 'Name',
  'component.transcription-library.table.column.status': 'Status',
  'component.transcription-library.table.column.duration': 'Duration',
  'component.transcription-library.table.column.created': 'Created',
  'component.transcription-library.table.column.expire': 'Expiry',
  'component.transcription-library.table.column.no-data.label': 'N/A',
  'component.transcription-library.delete.modal.title': 'Are you sure you want to delete?',
  'component.transcription-library.delete.modal.mobile.title': 'Delete',
  'component.transcription-library.delete.modal.confirm': 'Confirm',
  'component.transcription-library.delete.modal.cancel': 'Cancel',
  'component.transcription-library.edit.modal.title': 'Rename',
  'component.transcription-library.edit.modal.confirm': 'OK',
  'component.transcription-library.edit.modal.cancel': 'Cancel',
  'component.transcription-library.edit.tooltip': 'Rename',
  'component.transcription-library.delete.tooltip': 'Delete',
}

export default messages
