import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { Button } from 'components/common/general/Button'
import { Componentify } from 'components/common/other/Componentify'
import { GridBreakpointDimensions, GridBreakpoints } from 'redux/reducers/layout/types'
import { RootState } from 'redux/types/store'
import styles from './styles.module.css'

interface Props {
  className?: string
  isFileSelectDisabled?: boolean
}

export const DropZoneDescription: React.FC<Props> = ({
  className,
  isFileSelectDisabled
}) => {
  const { formatMessage } = useIntl()
  const browseFilesButtonText = formatMessage({
    id: 'component.audio-pipeline.file-upload.drop-zone.browse-btn-text'
  })
  const description = formatMessage({
    id: 'component.audio-pipeline.file-upload.drop-zone.description'
  })
  const patterns = useMemo(() => [browseFilesButtonText], [browseFilesButtonText])

  const breakpoint = useSelector((state: RootState) => state.layout.breakpoint)
  const isMobile =  GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.sm]

 const mobileDescription = formatMessage({
    id: 'component.audio-pipeline.file-upload.drop-zone.mobile.description'
  })

 const responsiveDescription = isMobile ? mobileDescription : description
  
  const handleMatch = useCallback(({
    text,
    index
  }: {
    text: string,
    index: number
  }) => (
    <Button
      key={`${text}-${index}`}
      text={text}
      disabled={isFileSelectDisabled}
      className={styles.button}
    />
  ), [isFileSelectDisabled])

  return (
    <Componentify
      text={responsiveDescription}
      patterns={patterns}
      className={classNames(styles.wrapper, className)}
      onMatch={handleMatch}
    />
  )
}