import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import { SearchInput } from 'components/common/data-entry/SearchInput'
import { SearchControls } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/SearchBar/SearchBarContent/SearchField/SearchControls'
import { getTranscriptionSearchMode } from 'redux/selectors/transcript-search'
import { setReplaceSearchText, setSearchText } from 'redux/actions/transcript-search'
import debounce from 'lodash/debounce'
import styles from './styles.module.css'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import { GridBreakpointDimensions, GridBreakpoints } from 'redux/reducers/layout/types'
import { RootState } from 'redux/types/store'
import { SearchTitle } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/SearchBar/SearchBarContent/SearchField/SearchTitle'
import config from 'config'

export const TranscriptSearchField: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const mode = useSelector(getTranscriptionSearchMode)
  const placeholder = formatMessage({
    id: 'component.transcript-search.search-input.placeholder',
  })
  
  const searchTitle = formatMessage({
    id: 'component.transcript-search.search.title',
    defaultMessage: 'Search'
  })
  
  const searchAndReplaceTitle = formatMessage({
    id: 'component.transcript-search.search-and-replace.title',
    defaultMessage: 'Search and Replace'
  })

  const breakpoint = useSelector((state: RootState) => state.layout.breakpoint)
  const isMobile = GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.sm]

 
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (mode === TranscriptSearchMode.SearchAndReplace) {
        const text = e.target.value.trim()

        return dispatch(setReplaceSearchText(text))
      }

      trackUserInteraction({
        interactionTarget: DataLayerTrackingEvents.ButtonClick,
        interactionMethod: ObserveEventInteractionMethod.Click,
        targetMetaData: {
          elementName: config.trackIds.searchText,
          elementValue: config.trackIds.searchText,
        },
      })

      dispatch(setSearchText(e.target.value))
    },
    [mode, dispatch]
  )

  const debouncedHandleSearch = useMemo(() => debounce(handleChange, 200), [handleChange])

  const title = mode === TranscriptSearchMode.SearchAndReplace 
    ? searchAndReplaceTitle 
    : searchTitle
 
    return (
      <div className={styles.wrapper}>
        <SearchTitle title={title} isMobile={isMobile}/>
        <div className={styles.searchWrapper}>
          <SearchInput
            className={classNames({
              [styles.searchAndReplace]: mode === TranscriptSearchMode.SearchAndReplace,
            })}
            inputClassName={styles.input}
            placeholder={placeholder}
            onChange={debouncedHandleSearch}
          />
          <SearchControls />
        </div>
      </div>
    )
}
