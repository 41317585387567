import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { Button } from 'components/common/general/Button'
import { transcribe } from 'redux/actions/audio-pipeline'
import { getIsLoading } from 'redux/selectors/loaders'
import { getIsTranscribeDisabled } from 'redux/selectors/audio-pipeline'
import config from 'config'
import styles from './styles.module.css'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'

export const TranscribeButton: React.FC = () => {
  const dispatch = useDispatch()
  const isTranscribing = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.TranscriptionData)
  )
  const isUploading = useSelector((state: RootState) => getIsLoading(state, Loaders.UploadFile))
  const isDisabled = useSelector(getIsTranscribeDisabled)
  const { formatMessage } = useIntl()

  const text = formatMessage({
    id: isTranscribing
      ? 'component.audio-pipeline.transcribe-cancel.button'
      : 'component.audio-pipeline.transcribe.button',
  })
  const isLoading = isUploading || isTranscribing

  const handleClick = useCallback(() => {
    dispatch(transcribe())

    trackUserInteraction({
      interactionTarget: DataLayerTrackingEvents.ButtonClick,
      interactionMethod: ObserveEventInteractionMethod.Click,
      targetMetaData: {
        elementName: config.trackIds.transcribe,
        elementValue: config.trackIds.transcribe,
      },
    })
  }, [dispatch])

  return (
    <Button
      text={text}
      dataTrackId={config.trackIds.transcribeButton}
      type="primary"
      danger={isLoading}
      disabled={isDisabled}
      className={styles.wrapper}
      onClick={handleClick}
    />
  )
}
