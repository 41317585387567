import React, { useCallback, } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'antd'
import { Input } from 'components/common/data-entry/Input'
import { Button } from 'components/common/general/Button'
import { getSpeakerNamesText } from 'redux/selectors/audio-pipeline'
import { setSpeakerNamesFieldText, setSpeakerNames} from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'

const { Text } = Typography

export const SpeakerNames: React.FC = () => {
  const dispatch = useDispatch()
  const speakerNames = useSelector(getSpeakerNamesText)
  const { formatMessage } = useIntl()
  
  const title = formatMessage({
    id: 'component.audio-pipeline.speaker-names.title'
  })
  const description = formatMessage({
    id: 'component.audio-pipeline.speaker-names.description'
  })
  const submitText = formatMessage({
    id: 'component.audio-pipeline.speaker-names.button'
  })

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSpeakerNamesFieldText(e.target.value))
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    dispatch(setSpeakerNames(speakerNames.split(',').map(value => value.trim())))
  }, [speakerNames, dispatch])

  return (
    <div className={styles.wrapper}>
      <Text className={styles.label}>{title}</Text>
      <Input
        autoFocus
        onChange={handleChange}
        value={speakerNames}
        placeholder={description}
      />
      <div className={styles.buttonWrapper}>
        <Button
          text={submitText}
          className={styles.button}
          disabled={!speakerNames}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}