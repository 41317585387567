import { MenuProps } from 'antd' 
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { intl } from 'utils/common/intl'
import { MoreOptions } from './types'

export const MORE_ITEMS_TRIGGER: ("hover" | "contextMenu" | "click")[] = ['click']

export const getMenuItems: MenuProps['items'] = [
  {
    key: MoreOptions.Search,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.options.tabs.search.label'
    }),
    icon: <CustomIcon type={CustomIconType.Search} />,
  },
  {
    key: MoreOptions.SearchAndReplace,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.options.tabs.search-and-replace.label'
    }),
    icon: <CustomIcon type={CustomIconType.SearchAndReplace} />,
  },
  {
    key: MoreOptions.Speakers,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.options.tabs.speakers.label'
    }),
    icon: <CustomIcon type={CustomIconType.User} />,
  }
]