import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FileUploadDropZone } from 'components/common/data-entry/FileUploadDropZone'
import { setFileUploadProgress } from 'redux/actions/audio-pipeline'
import { setSelectedFile } from 'redux/actions/audio-pipeline'
import { getSelectedFile } from 'redux/selectors/audio-pipeline'
import styles from './styles.module.css'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import config from 'config'

export const FileUploadField: React.FC = () => {
  const dispatch = useDispatch()
  const selectedFile = useSelector(getSelectedFile)

  const handleSelectFile = useCallback(
    (file: File | null) => {
      dispatch(setSelectedFile(file))

      trackUserInteraction({
        interactionTarget: DataLayerTrackingEvents.ButtonClick,
        interactionMethod: ObserveEventInteractionMethod.Click,
        targetMetaData: {
          elementName: config.trackIds.uploadFile,
          elementValue: config.trackIds.uploadFile,
        },
      })
    },
    [dispatch]
  )

  const handleRemoveFile = useCallback(() => {
    dispatch(setSelectedFile(null))
  }, [dispatch])

  useEffect(() => {
    if (selectedFile) {
      dispatch(setFileUploadProgress(0))
    }
  }, [dispatch, selectedFile])

  return (
    <div className={styles.wrapper}>
      <FileUploadDropZone
        file={selectedFile}
        onSelectFile={handleSelectFile}
        onRemoveFile={handleRemoveFile}
      />
    </div>
  )
}
