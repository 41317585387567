import { merge } from 'lodash'
import {
  DEFAULT_DATA_LAYER_PAGE_VIEW_PAYLOAD,
  EventTypes,
  ObserveEventSchemaId,
  TrackingEvents,
} from 'utils/tracking/constants'
import {
  DataLayerPageViewPayload,
  ObserverEventPayload,
  ObserveUserInteractionPayload,
} from 'utils/tracking/types'
import { getDepartmentFromStorage } from 'utils/common/department'

declare const window: Window & {
  dataLayer: Record<string, unknown>[]
  nukDl: DataLayerPageViewPayload
  aitLib?: {
    observeEvent: (payload: ObserverEventPayload) => void
  }
}

export const pushTrackEvent = (event: TrackingEvents) => {
  try {
    window.dataLayer.push({ event })
  } catch {
    console.warn(`Unable to push track event: ${event}`)
  }
}

export const trackDataLayerLoginPageView = () => {
  const payload: DataLayerPageViewPayload = {
    page: {
      pageName: 'login',
      section: 'login',
    },
    user: {
      department: 'N/A',
      restrictions: 'public',
    },
  }

  pushTrackDataLayerPageView(payload)
}

export const trackDataLayerListTranscriptsPageView = () => {
  const department = getDepartmentFromStorage()

  const payload: DataLayerPageViewPayload = {
    page: {
      pageName: 'list-transcripts',
      section: 'transcripts',
    },
    user: {
      department,
      restrictions: 'restricted',
    },
  }

  pushTrackDataLayerPageView(payload)
}

export const trackDataLayerListTranscriptsExpiredPageView = () => {
  const department = getDepartmentFromStorage()

  const payload: DataLayerPageViewPayload = {
    page: {
      pageName: 'list-transcripts',
      section: 'expired',
    },
    user: {
      department,
      restrictions: 'restricted',
    },
  }

  pushTrackDataLayerPageView(payload)
}

export const trackDataLayerTranscriptPageView = () => {
  const department = getDepartmentFromStorage()

  const payload: DataLayerPageViewPayload = {
    page: {
      pageName: 'view-transcript',
      section: 'transcript',
    },
    user: {
      department,
      restrictions: 'restricted',
    },
  }

  pushTrackDataLayerPageView(payload)
}

export const trackDataLayerTranscriptAdditionalOutputPageView = () => {
  const department = getDepartmentFromStorage()

  const payload: DataLayerPageViewPayload = {
    page: {
      pageName: 'view-transcript',
      section: 'additional-output',
    },
    user: {
      department,
      restrictions: 'restricted',
    },
  }

  pushTrackDataLayerPageView(payload)
}

export const trackUserInteraction = (payload: ObserveUserInteractionPayload) => {
  const event: ObserverEventPayload = {
    schemaId: ObserveEventSchemaId.Event,
    eventType: EventTypes.UserInteraction,
    interactionTimestamp: Date.now().toString(),
    ...payload,
  }

  observeEvent(event)
}

const pushTrackDataLayerPageView = (payload: DataLayerPageViewPayload) => {
  const mergedPayload = merge(DEFAULT_DATA_LAYER_PAGE_VIEW_PAYLOAD, payload)

  window.nukDl = mergedPayload
}

const observeEvent = (payload: ObserverEventPayload) => {
  if (!window.aitLib) {
    console.warn('aitLib is not available')
    return
  }

  window.aitLib.observeEvent(payload)
}
