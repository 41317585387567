import React from 'react'
import { useSelector } from 'react-redux' 
import { LogoutButton } from 'components/common/auth/LogoutButton'
import { HelpLink } from 'components/layouts/common/Header/HelpLink'
import { UserProfile } from 'components/layouts/common/Header/UserProfile'
import { MenuInfo } from './MenuInfo'
import { GridBreakpointDimensions, GridBreakpoints } from 'redux/reducers/layout/types'
import { RootState } from 'redux/types/store'
import styles from './styles.module.css'

export const UserInfo: React.FC = () => {
  const breakpoint = useSelector((state: RootState) => state.layout.breakpoint)
  const isCollapsed = GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.md]

  if (isCollapsed) {
    return <MenuInfo />
  }

  return (
    <div className={styles.wrapper}>
      <HelpLink />
      <UserProfile />
      <LogoutButton />
    </div>
  )
}