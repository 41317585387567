import { Link } from 'react-router-dom'
import { MenuProps } from 'antd'
import { intl } from 'utils/common/intl'
import config from 'config'
import { HelpLinkOptions } from 'components/layouts/common/Header/HelpLink/types'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'

export const HELP_LINK_ITEMS_TRIGGER: ('hover' | 'contextMenu' | 'click')[] = ['click']

const trackGuideLinkClick = () => {
  trackUserInteraction({
    interactionTarget: DataLayerTrackingEvents.NavigationSelection,
    interactionMethod: ObserveEventInteractionMethod.Click,
    targetMetaData: {
      elementName: config.trackIds.transcribeGuideClick,
      elementValue: config.trackIds.transcribeGuideClick,
    },
  })
}

const trackReportIssueClick = () => {
  trackUserInteraction({
    interactionTarget: DataLayerTrackingEvents.NavigationSelection,
    interactionMethod: ObserveEventInteractionMethod.Click,
    targetMetaData: {
      elementName: config.trackIds.reportAnIssueClick,
      elementValue: config.trackIds.reportAnIssueClick,
    },
  })
}

export const HELP_LINK_ITEMS: MenuProps['items'] = [
  {
    key: HelpLinkOptions.TranscribeGuide,
    label: (
      <Link to={config.links.newsTranscribeGuide} target="_blank" onClick={trackGuideLinkClick}>
        {intl.formatMessage({
          id: 'component.common.help-link.text',
        })}
      </Link>
    ),
  },
  {
    key: HelpLinkOptions.ReportAnIssue,
    label: (
      <Link to={config.links.reportAnIssue} onClick={trackReportIssueClick} target="_blank">
        {intl.formatMessage({
          id: 'component.common.report-issue.text',
        })}
      </Link>
    ),
  },
]
