import { Tabs } from 'components/common/data-display/Tabs'
import { PropsWithChildren } from 'react'

import styles from './styles.module.css'
import { TabsProps } from 'antd'

interface Props extends PropsWithChildren {
  tabs: TabsProps['items']
  activeTab: string
  onTabsChange: (key: string) => void
}

export const TranscriptFilters: React.FC<Props> = ({ tabs, children, activeTab, onTabsChange }) => {
  return (
    <div className={styles.container}>
      <Tabs className={styles.tabs} items={tabs} activeKey={activeTab} onChange={onTabsChange} />
      {children}
    </div>
  )
}
