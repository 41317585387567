import {
  TranscriptActionOptions
} from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/types'

export const getParagraphPadding = (isMobile: boolean, activeActionTab: TranscriptActionOptions | null) => {
  if (!isMobile || !activeActionTab) return '0px'

  switch (activeActionTab) {
    case TranscriptActionOptions.SearchAndReplace:
      return '74px'
    case TranscriptActionOptions.Speakers:
      return '30px'
    default:
      return '0px'
  }
}