import React, { forwardRef, useCallback } from 'react'
import { Input as BaseInput, InputProps, InputRef } from 'antd'
import classNames from 'classnames'
import { CloseOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

interface Props extends InputProps {
  className?: string
  renderClearIcon?: () => React.ReactNode
}

export const Input = forwardRef<InputRef, Props>(
  ({ value, placeholder, className, allowClear, autoFocus, onChange }, ref) => {
    const clearIconRenderer = useCallback(
      () =>
        allowClear && {
          clearIcon: allowClear && <CloseOutlined />,
        },
      [allowClear]
    )

    return (
      <BaseInput
        ref={ref}
        allowClear={clearIconRenderer()}
        autoFocus={autoFocus}
        value={value}
        placeholder={placeholder}
        rootClassName={classNames(styles.wrapper, className)}
        onChange={onChange}
      />
    )
  }
)
