import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { TranscriptContentTabs } from 'components/audio-pipeline/TranscriptPageTabs/constants'
import { Card } from 'components/common/data-display/Card'
import { AudioPlayerSection } from 'components/audio-pipeline/AudioPlayerSection'
import { TranscriptPageTabs } from 'components/audio-pipeline/TranscriptPageTabs'
import { TranscriptTextContent } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent'
import { TranscriptAdditionalContent } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptAdditionalContent'
import { MobileAdditionalOuputTabs } from 'components/audio-pipeline/TranscriptPageTabs/MobileAdditionalOuputTabs'
import { MoblieAdditionalOutputTabs } from 'components/audio-pipeline/TranscriptPageTabs/MobileAdditionalOuputTabs/constants'
import { fetchTranscription, fetchTranscriptions } from 'redux/actions/transcription-library'
import { clearInputFields } from 'redux/actions/audio-pipeline'
import { getIsLoading } from 'redux/selectors/loaders'
import { getTranscriptionLibraryItems } from 'redux/selectors/transcription-library'
import { getBreakPoint } from 'redux/selectors/layout' 
import { GridBreakpointDimensions, GridBreakpoints } from 'redux/reducers/layout/types'
import styles from './styles.module.css'
import {
  trackDataLayerTranscriptAdditionalOutputPageView,
  trackDataLayerTranscriptPageView,
} from 'utils/tracking'

export const Transcript: React.FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const transcriptions = useSelector(getTranscriptionLibraryItems)
  const [activeKey, setActiveKey] = useState<string>(TranscriptContentTabs.Transcript)
  const [mobileActiveKey, setMobileActiveKey] = useState<string>(MoblieAdditionalOutputTabs.Summary)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.TranscriptionData)
  )
  const breakpoint = useSelector(getBreakPoint)
  const isMobile = GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.sm]

  const handleChange = useCallback((id: string) => {
    setActiveKey(id)
  }, [])

  const handleMobileTabChange = useCallback((id: string) => {
    setMobileActiveKey(id)
  }, [])

  useEffect(() => {
    if (activeKey === TranscriptContentTabs.AdditionalOutput) {
      trackDataLayerTranscriptAdditionalOutputPageView()
    } else {
      trackDataLayerTranscriptPageView()
    }
  }, [activeKey])

  useEffect(() => {
    if (!transcriptions.length) {
      dispatch(fetchTranscriptions())
    }
  }, [dispatch, transcriptions])

  useEffect(() => {
    if (id) {
      dispatch(fetchTranscription(id))
    }

    dispatch(clearInputFields())
  }, [id, dispatch])

  return (
    <div className={styles.wrapper}>
      <AudioPlayerSection />
      <TranscriptPageTabs activeKey={activeKey} onChange={handleChange} />
      {isMobile && activeKey === TranscriptContentTabs.AdditionalOutput && (
        <MobileAdditionalOuputTabs
          activeKey={mobileActiveKey}
          onChange={handleMobileTabChange}
        />
      )}
      <Card
        isLoading={isLoading}
        contentWrapperClassName={styles.content}
        className={classNames(styles.cardWrapper, styles[activeKey])}
      >
        <TranscriptTextContent isVisible={activeKey === TranscriptContentTabs.Transcript} />
        {activeKey === TranscriptContentTabs.AdditionalOutput && (
          <TranscriptAdditionalContent
            activeTab={isMobile ? mobileActiveKey : undefined}
          />
        )}
      </Card>
    </div>
  )
}
