import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useOktaAuth } from '@okta/okta-react'
import { Button } from 'antd'
import styles from './styles.module.css'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import classNames from 'classnames'
import config from 'config'

interface Props {
  className?: string
}

export const LogoutButton: React.FC<Props> = ({ className }) => {
  const { oktaAuth } = useOktaAuth()
  const { formatMessage } = useIntl()
  const text = formatMessage({
    id: 'component.common.auth.logout',
  })

  const wrapperClassNames = classNames(styles.wrapper, className)

  const handleClick = useCallback(() => {
    oktaAuth.signOut()

    trackUserInteraction({
      interactionTarget: DataLayerTrackingEvents.ButtonClick,
      interactionMethod: ObserveEventInteractionMethod.Click,
      targetMetaData: {
        elementName: config.trackIds.logout,
        elementValue: config.trackIds.logout,
      },
    })
  }, [oktaAuth])

  return (
    <Button className={wrapperClassNames} onClick={handleClick}>
      {text}
    </Button>
  )
}
