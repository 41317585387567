import { TranscriptionLibraryItem } from 'redux/reducers/transcription-library/types'

export enum TranscriptFilterTabs {
  Transcripts = 'transcripts',
  Expiring = 'expiring',
}

export interface ConsumeTranscriptionsPayload {
  data: TranscriptionLibraryItem[]
}

export interface UpdateTranscriptionPayload {
  id: string
  name: string
}

export interface FetchTranscriptionPayload {
  id: string
}

export interface EditTranscriptionPayload {
  id: string
}

export interface SetEditTranscriptionIdPayload {
  id: string | null
}

export interface SetTranscriptsActiveFilter {
  key: TranscriptFilterTabs
}

export interface DeleteTranscriptionPayload {
  id: string
}

export interface ConfirmEditTranscriptionPayload {
  name: string
}

export type PrepareConsumeTranscriptions = (data: TranscriptionLibraryItem[]) => {
  payload: ConsumeTranscriptionsPayload
}

export type PrepareFetchTranscription = (id: string) => {
  payload: FetchTranscriptionPayload
}

export type PrepareUpdateTranscription = (data: UpdateTranscriptionPayload) => {
  payload: UpdateTranscriptionPayload
}

export type PrepareEditTranscription = (id: string) => {
  payload: EditTranscriptionPayload
}

export type PrepareSetEditedTranscriptionId = (id: string | null) => {
  payload: SetEditTranscriptionIdPayload
}

export type PrepareConfirmEditTranscription = (name: string) => {
  payload: ConfirmEditTranscriptionPayload
}

export type PrepareDeleteTranscriptionPayload = (id: string) => {
  payload: DeleteTranscriptionPayload
}

export type PrepareSetTranscriptsActiveFilter = (key: TranscriptFilterTabs) => {
  payload: SetTranscriptsActiveFilter
}
