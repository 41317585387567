import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button, Typography } from 'antd'
import { revertEditedWord } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import config from 'config'

const { Text } = Typography

interface Props {
  id: string
  text: string
}

export const InitialWordData: React.FC<Props> = ({ id, text }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const buttonText = formatMessage({
    id: 'component.audio-pipeline.transcript.edit.undo',
  })
  const initialTextLabel = formatMessage({
    id: 'component.audio-pipeline.transcript.edit.initial',
  })

  const handleClick = useCallback(() => {
    dispatch(revertEditedWord(id))

    trackUserInteraction({
      interactionTarget: DataLayerTrackingEvents.ButtonClick,
      interactionMethod: ObserveEventInteractionMethod.Click,
      targetMetaData: {
        elementName: config.trackIds.undoEditWord,
        elementValue: config.trackIds.undoEditWord,
      },
    })
  }, [id, dispatch])

  return (
    <div className={styles.wrapper}>
      <Text>{initialTextLabel}</Text>
      <Text className={styles.text}>{text}</Text>
      <Button danger type="text" className={styles.undo} onClick={handleClick}>
        {buttonText}
      </Button>
    </div>
  )
}
