import React from 'react'
import { TAB_ITEMS } from './constants'
import { Card } from 'components/common/data-display/Card'
import { Tabs } from 'components/common/data-display/Tabs'
import classNames from 'classnames'
import styles from './styles.module.css'

interface Props {
  onChange: (id: string) => void
  activeKey: string
  className?: string
}

export const MobileAdditionalOuputTabs: React.FC<Props> = ({ 
  onChange, 
  activeKey, 
  className 
}) => {
  return (
    <div className={styles.wrapper}>
      <Card 
        className={classNames(styles.cardWrapper, className)}
        contentWrapperClassName={styles.content}
      >
        <Tabs
          items={TAB_ITEMS}
          className={styles.tabs}
          activeKey={activeKey}
          onChange={onChange}
        />
      </Card>
    </div>
  )
}