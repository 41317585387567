import React from 'react'
import { TranscriptionStatusValue } from 'components/audio-pipeline/Transcriptions/Status'
import { TranscriptionLibraryItemActions } from 'components/audio-pipeline/Transcriptions/Actions'
import { TranscriptionStatus } from 'redux/reducers/transcription-library/types'
import styles from './styles.module.css'

interface Props {
  status: TranscriptionStatus | undefined
  label: string | null
  rowId: string
  disabled: boolean
}

export const StatusAndActions: React.FC<Props> = ({ status, label, rowId, disabled }) => {
  return (
    <div className={styles.wrapper}>
      {status && <TranscriptionStatusValue status={status} label={label} />}
      <TranscriptionLibraryItemActions rowId={rowId} disabled={disabled} />
    </div>
  )
}
