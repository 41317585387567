import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { ModalIds } from 'redux/reducers/modals/types'
import { InputRef, Modal } from 'antd'
import { Input } from 'components/common/data-entry/Input'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { ModalTitle } from 'components/audio-pipeline/Transcriptions/Modals/Title'
import { getOpenModalIds } from 'redux/selectors/modals'
import { getTranscriptionLibraryEditedItemName } from 'redux/selectors/transcription-library'
import {
  cancelEditTranscription,
  confirmEditTranscription,
} from 'redux/actions/transcription-library'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from './styles.module.css'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import config from 'config'

export const Edit: React.FC = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const { formatMessage } = useIntl()
  const openModalIds = useSelector(getOpenModalIds)
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.EditTranscription)
  )
  const isModalOpen = openModalIds.includes(ModalIds.EditTranscription)
  const originalName = useSelector(getTranscriptionLibraryEditedItemName)
  const inputRef = useRef<InputRef | null>(null)

  const okButtonProps = useMemo(
    () => ({
      disabled: !name,
      loading: isLoading,
    }),
    [name, isLoading]
  )

  const cancelButtonProps = useMemo(
    () => ({
      disabled: isLoading,
    }),
    [isLoading]
  )

  const title = formatMessage({ id: 'component.transcription-library.edit.modal.title' })
  const confirm = formatMessage({ id: 'component.transcription-library.edit.modal.confirm' })
  const cancel = formatMessage({ id: 'component.transcription-library.edit.modal.cancel' })

  const handleConfirm = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      dispatch(confirmEditTranscription(name))

      trackUserInteraction({
        interactionTarget: DataLayerTrackingEvents.ButtonClick,
        interactionMethod: ObserveEventInteractionMethod.Click,
        targetMetaData: {
          elementName: config.trackIds.renameTranscription,
          elementValue: config.trackIds.renameTranscription,
        },
      })
    },
    [dispatch, name]
  )

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      dispatch(cancelEditTranscription())
    },
    [dispatch]
  )

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }, [])

  useEffect(() => {
    setName(originalName)
  }, [originalName])

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => inputRef.current?.focus())
    }
  }, [isModalOpen])

  return (
    <Modal
      open={isModalOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okText={confirm}
      cancelText={cancel}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      className={styles.wrapper}
      title={<ModalTitle text={title} />}
      closeIcon={<CustomIcon type={CustomIconType.Close} />}
      centered
      transitionName="ant-fade"
    >
      <Input ref={inputRef} value={name} className={styles.input} onChange={handleChange} />
    </Modal>
  )
}
