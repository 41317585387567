import React from 'react'
import { TabsProps } from 'antd'
import styles from './styles.module.css'

export interface Props {
  activeKey: string,
  items: TabsProps['items']
  isVisible: boolean
}

export const MobileTabContent: React.FC<Props> = ({ activeKey, items, isVisible }) => {
  if (!isVisible || !activeKey) {
    return null
  }

  const activeTabItem = items?.find(item => item.key === activeKey)

  return (
    <div className={styles.wrapper}>
      {activeTabItem?.children}
    </div>
  )
}