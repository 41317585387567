import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Routes } from 'routes/constants'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import {
  RowMouseEventHandlerParams,
  SortDirection,
  TableCellProps,
  TableHeaderRowProps,
  WindowScroller,
} from 'react-virtualized'
import { SortOptionsRenderer } from 'components/common/data-display/Table/types'
import { TranscriptionLibraryListItem } from 'components/audio-pipeline/Transcriptions/types'
import {
  TranscriptionLibraryItemDataKeys,
  TranscriptionStatus,
} from 'redux/reducers/transcription-library/types'
import {
  COLUMNS,
  TABLE_HEADER_PORTAL_NODE_ID,
  COLLAPSED_COLUMNS,
  MOBILE_COLUMNS,
  MOBILE_ROW_HEIGHT,
  DESKTOP_ROW_HEIGHT,
} from 'components/audio-pipeline/Transcriptions/constants'
import { Card } from 'components/common/data-display/Card'
import { Table } from 'components/common/data-display/Table'
import { TranscriptionStatusValue } from 'components/audio-pipeline/Transcriptions/Status'
import { TranscriptionTableCellValue } from 'components/audio-pipeline/Transcriptions/DefaultTableCellValue'
import { TranscriptionLibraryItemActions } from 'components/audio-pipeline/Transcriptions/Actions'
import { TableHeaderRow } from 'components/common/data-display/Table/TableHeaderRow'
import { TranscriptionsSortOption } from 'components/audio-pipeline/Transcriptions/TableSortOption'
import { MobileContent } from 'components/common/data-display/Table/MobileContent'
import {
  fetchTranscriptions,
  setTranscriptsActiveFilter,
} from 'redux/actions/transcription-library'
import { getIsLoading } from 'redux/selectors/loaders'
import {
  getTranscribeListTabs,
  getTranscriptionActiveFilter,
  getTranscriptionLibraryListItemsByFilter,
} from 'redux/selectors/transcription-library'
import { replaceUrlParams } from 'api/utils'
import { GridBreakpoints, GridBreakpointDimensions } from 'redux/reducers/layout/types'
import styles from './styles.module.css'
import { TranscriptFilters } from 'components/audio-pipeline/TranscriptFilters'
import { TranscriptFilterTabs } from 'redux/actions/transcription-library/types'
import { NoExpiringTranscriptions } from 'components/common/data-display/Table/NoExpiringTranscriptions'
import { DefaultNoData } from 'components/common/data-display/Table/DefaultNoData'
import {
  trackDataLayerListTranscriptsExpiredPageView,
  trackDataLayerListTranscriptsPageView,
  trackUserInteraction,
} from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import config from 'config'

export const Transcriptions: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.Transcriptions))
  const items = useSelector(getTranscriptionLibraryListItemsByFilter)
  const activeFilter = useSelector(getTranscriptionActiveFilter)
  const filterTabs = useSelector(getTranscribeListTabs)
  const breakpoint = useSelector((state: RootState) => state.layout.breakpoint)

  const isMobile =
    GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.sm]
  const isTablet =
    GridBreakpointDimensions[breakpoint] <= GridBreakpointDimensions[GridBreakpoints.md]

  const tableColumns = useMemo(() => {
    if (isMobile) {
      return MOBILE_COLUMNS
    }
    if (isTablet) {
      return COLLAPSED_COLUMNS
    }

    return COLUMNS
  }, [isMobile, isTablet])

  const cellRenderer = useCallback(
    (props: TableCellProps) => {
      const { cellData, dataKey, rowData } = props
      const { id, status, expiryAfterDays, name, duration, createdAt } =
        rowData as TranscriptionLibraryListItem
      const disabled = status === TranscriptionStatus.Transcribing

      if (isMobile && status) {
        return (
          <MobileContent
            id={id}
            status={status}
            expiryAfterDays={expiryAfterDays}
            name={name}
            duration={duration}
            createdAt={createdAt}
          />
        )
      }

      if (dataKey === TranscriptionLibraryItemDataKeys.Status) {
        const label =
          cellData === TranscriptionStatus.Expiring
            ? formatMessage(
                {
                  id: `component.transcription-library.transcription.status.expiring`,
                },
                { days: expiryAfterDays }
              )
            : null

        return <TranscriptionStatusValue status={cellData} label={label} />
      }

      if (dataKey === TranscriptionLibraryItemDataKeys.Actions) {
        return <TranscriptionLibraryItemActions rowId={id} disabled={disabled} />
      }

      return <TranscriptionTableCellValue id={dataKey} value={cellData} disabled={disabled} />
    },
    [formatMessage, isMobile]
  )

  const handleRowClick = useCallback(
    ({ rowData }: RowMouseEventHandlerParams) => {
      const { id, status } = rowData

      if (status === TranscriptionStatus.Transcribed || status === TranscriptionStatus.Expiring) {
        const url = replaceUrlParams({
          url: Routes.Transcript,
          urlParams: { id },
        })

        navigate(url)

        trackUserInteraction({
          interactionTarget: DataLayerTrackingEvents.NavigationSelection,
          interactionMethod: ObserveEventInteractionMethod.Click,
          targetMetaData: {
            elementName: config.trackIds.openTranscription,
            elementValue: config.trackIds.openTranscription,
          },
        })
      }
    },
    [navigate]
  )

  const renderCustomHeader = useCallback(
    ({ style, height, width, scrollbarWidth, className, columns }: TableHeaderRowProps) => {
      const domNode = document.getElementById(TABLE_HEADER_PORTAL_NODE_ID)

      if (!domNode) {
        return null
      }

      const headerRow = (
        <TableHeaderRow
          style={style}
          className={classNames(className, styles.headerRow)}
          height={height}
          width={width}
          scrollbarWidth={scrollbarWidth}
          columns={columns}
        />
      )

      return createPortal(headerRow as any, domNode)
    },
    []
  )
  const renderSortOptions = useCallback<SortOptionsRenderer>(
    ({ selectedOption, onChange }) => (
      <TranscriptionsSortOption value={selectedOption} onChange={onChange} />
    ),
    []
  )

  const handleTabFilterChange = useCallback(
    (key: string) => {
      dispatch(setTranscriptsActiveFilter(key as TranscriptFilterTabs))
    },
    [dispatch]
  )

  useEffect(() => {
    if (activeFilter === TranscriptFilterTabs.Transcripts) {
      trackDataLayerListTranscriptsPageView()
    } else {
      trackDataLayerListTranscriptsExpiredPageView()
    }
  }, [activeFilter])

  useEffect(() => {
    dispatch(fetchTranscriptions())
  }, [dispatch])

  const renderNoData = useCallback(
    () =>
      activeFilter === TranscriptFilterTabs.Expiring ? (
        <NoExpiringTranscriptions className={styles.noData} />
      ) : (
        <DefaultNoData className={styles.noData} />
      ),
    [activeFilter]
  )

  return (
    <TranscriptFilters
      tabs={filterTabs}
      activeTab={activeFilter}
      onTabsChange={handleTabFilterChange}
    >
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => {
          return (
            <>
              <div id={TABLE_HEADER_PORTAL_NODE_ID} className={styles.stickyHeader} />
              <Card
                className={classNames(styles.card, {
                  [styles.noMinHeight]: !!items.length,
                })}
                isLoading={isLoading && !items.length}
              >
                <Table
                  rows={items}
                  height={height}
                  renderCustomHeader={renderCustomHeader}
                  defaultSortBy={TranscriptionLibraryItemDataKeys.CreatedAt}
                  defaultSortDirections={SortDirection.DESC}
                  renderSortOptions={renderSortOptions}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  onScroll={onChildScroll}
                  columns={tableColumns}
                  onRowClick={handleRowClick}
                  cellRenderer={cellRenderer}
                  noDataClassName={styles.noData}
                  renderNoData={renderNoData}
                  rowHeight={isMobile ? MOBILE_ROW_HEIGHT : DESKTOP_ROW_HEIGHT}
                />
              </Card>
            </>
          )
        }}
      </WindowScroller>
    </TranscriptFilters>
  )
}
