import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { Empty, Typography } from 'antd'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import styles from './styles.module.css'

const { Text } = Typography

interface Props {
  className?: string
  description?: string | null
  message?: string | null
  shouldRenderDescription?: boolean
  shouldRenderMessage?: boolean
  icon?: React.ReactNode
}

export const NoData: React.FC<Props> = ({
  className,
  message,
  description,
  shouldRenderDescription,
  shouldRenderMessage,
  icon = <CustomIcon type={CustomIconType.File} />,
}) => {
  const { formatMessage } = useIntl()
  const defaultDescription = formatMessage({
    id: 'component.common.transcript-page.no-data.description',
  })

  const defaultMessage = formatMessage({
    id: 'component.common.no-data.description.message',
  })
  const displayMessage = message || defaultMessage
  const descriptionText = description || defaultDescription
  const text = shouldRenderDescription ? descriptionText : null

  return (
    <div className={styles.emptyStateWrapper}>
      <Empty description={text} className={classNames(styles.wrapper, className)} image={icon} />
      {shouldRenderMessage && <Text className={styles.noDataMessage}>{displayMessage}</Text>}
    </div>
  )
}
