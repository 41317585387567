import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button } from 'components/common/general/Button'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { Dropdown, MenuProps } from 'antd'
import { getMenuItems, MORE_ITEMS_TRIGGER } from './moreItems'
import { TranscriptActionOptions } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/types'
import { TranscriptSearchMode } from 'redux/reducers/transcript-search/types'
import { SEARCH_MODE_BY_SELECTED_OPTION } from 'components/audio-pipeline/TranscriptPageTabs/TranscriptTextContent/TranscriptOptionsBar/constants'
import { setSearchMode } from 'redux/actions/transcript-search'
import { setSelectedTranscriptActionTab } from 'redux/actions/audio-pipeline'
import classNames from 'classnames'
import styles from './styles.module.css'

interface Props {
  className?: string
}

export const OptionsButton: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const text = formatMessage({
    id: 'component.audio-pipeline.options-button.label',
  })
  
  const handleChange: MenuProps['onClick'] = ({ key }) => {
    const actionOption = key as TranscriptActionOptions
    const searchMode = SEARCH_MODE_BY_SELECTED_OPTION[key as TranscriptSearchMode]
    
    dispatch(setSearchMode(searchMode))
    dispatch(setSelectedTranscriptActionTab(actionOption))
  }
  
  const menuItems = getMenuItems
  
  return (
    <Dropdown 
      menu={{ 
        items: menuItems,
        onClick: handleChange
      }} 
      trigger={MORE_ITEMS_TRIGGER} 
      placement="bottomRight"
    >
      <Button
        type="primary"
        text={text}
        className={classNames(styles.wrapper, className)}
        icon={<CustomIcon className={styles.icon} type={CustomIconType.More} />}
      />
    </Dropdown>
  )
}