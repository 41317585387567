import React, { useCallback } from 'react'
import classNames from 'classnames'
import { ReactComponent as Search } from 'assets/icons/search.svg'
import { ReactComponent as SearchAndReplace } from 'assets/icons/search-and-replace.svg'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg'
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg'
import { ReactComponent as User } from 'assets/icons/user.svg'
import { ReactComponent as AiGenerate } from 'assets/icons/ai-generate.svg'
import { ReactComponent as Info } from 'assets/icons/info.svg'
import { ReactComponent as Attachment } from 'assets/icons/attachment.svg'
import { ReactComponent as Error } from 'assets/icons/error.svg'
import { ReactComponent as Edit } from 'assets/icons/edit.svg'
import { ReactComponent as Delete } from 'assets/icons/delete.svg'
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg'
import { ReactComponent as SortArrowDown } from 'assets/icons/sort-arrow-down.svg'
import { ReactComponent as SortArrowUp } from 'assets/icons/sort-arrow-up.svg'
import { ReactComponent as File } from 'assets/icons/file.svg'
import { ReactComponent as More } from 'assets/icons/more.svg'
import { ReactComponent as AlertIcon } from 'assets/icons/alert-icon.svg'
import { ReactComponent as NoExpiringTranscriptsIcon } from 'assets/icons/no-expiring-transcripts.svg'
import { ReactComponent as Menu } from 'assets/icons/menu.svg'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import styles from './styles.module.css'

interface Props {
  type: CustomIconType
  className?: string
  onClick?: () => void
}

export const CustomIcon: React.FC<Props> = ({ type, className, onClick }) => {
  const renderIcon = useCallback(() => {
    switch (type) {
      case CustomIconType.Search:
        return <Search />
      case CustomIconType.SearchAndReplace:
        return <SearchAndReplace />
      case CustomIconType.Close:
        return <Close />
      case CustomIconType.ArrowUp:
        return <ArrowUp />
      case CustomIconType.ArrowDown:
        return <ArrowDown />
      case CustomIconType.User:
        return <User />
      case CustomIconType.Copy:
        return <Copy />
      case CustomIconType.AiGenerate:
        return <AiGenerate />
      case CustomIconType.Info:
        return <Info />
      case CustomIconType.Attachment:
        return <Attachment />
      case CustomIconType.Error:
        return <Error />
      case CustomIconType.Edit:
        return <Edit />
      case CustomIconType.Delete:
        return <Delete />
      case CustomIconType.ArrowBack:
        return <ArrowBack />
      case CustomIconType.SortArrowUp:
        return <SortArrowUp />
      case CustomIconType.SortArrowDown:
        return <SortArrowDown />
      case CustomIconType.File:
        return <File />
      case CustomIconType.More:
        return <More />
      case CustomIconType.AlertIcon:
        return <AlertIcon />
      case CustomIconType.NoExpiringTranscriptsIcon:
        return <NoExpiringTranscriptsIcon />
      case CustomIconType.Menu:
        return <Menu />  
      default:
        return null
    }
  }, [type])

  return (
    <span onClick={onClick} className={classNames(styles.wrapper, className)}>
      {renderIcon()}
    </span>
  )
}
