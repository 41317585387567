import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { DropZoneFileInfo } from 'components/common/data-entry/FileUploadDropZone/DropZone/FileInfo'
import { Alert, Typography } from 'antd'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { getIsLoading } from 'redux/selectors/loaders'
import { getFileValidationError } from 'utils/audio-pipeline/file-upload/validation'
import { getFileError, getFileUrl } from 'redux/selectors/audio-pipeline'
import { setFileError } from 'redux/actions/audio-pipeline'
import styles from './styles.module.css'
import { DropZoneMessage } from 'components/common/data-entry/FileUploadDropZone/DropZone/Message'

const { Text } = Typography

interface Props {
  fileName?: string
  onClick?: () => void
  onDrop: (file: File | null) => void
  onRemoveFile?: () => void
}

export const FileDropZone: React.FC<Props> = ({ fileName, onClick, onRemoveFile, onDrop }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const error = useSelector(getFileError)
  const [isDragEntered, setDragEntered] = useState(false)
  const fileUrl = useSelector(getFileUrl)
  const isUploading = useSelector((state: RootState) => getIsLoading(state, Loaders.UploadFile))
  const isTranscribing = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.TranscriptionData)
  )
  const isLoading = isUploading || isTranscribing
  const isProcessing = !fileUrl && isLoading
  const isDisabled = isLoading || !!fileName || !!fileUrl
  const shouldRenderDescription = !fileName && !isProcessing
  const dragEnteredPlaceholder = formatMessage({
    id: 'component.audio-pipeline.file-upload.drop-zone.active.placeholder',
  })

  const handleDragOver = useCallback((e: any) => {
    e.preventDefault()
    setDragEntered(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setDragEntered(false)
  }, [])

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault()

      dispatch(setFileError(null))
      setDragEntered(false)

      const items: DataTransferItem[] = Array.from(e.dataTransfer.items)
      const validationError = getFileValidationError(items)

      const [item] = items
      const file = item.getAsFile()

      if (error && !validationError) {
        dispatch(setFileError(null))
      }

      if (validationError) {
        dispatch(setFileError(validationError))
      }

      onDrop(file)
    },
    [dispatch, error, onDrop]
  )

  const handleClick = useCallback(() => {
    if (!isDisabled && onClick) {
      onClick()
    }
  }, [isDisabled, onClick])

  const handleRemoveFile = useCallback(() => {
    if (error) {
      dispatch(setFileError(null))
    }

    if (onRemoveFile) {
      onRemoveFile()
    }
  }, [dispatch, error, onRemoveFile])

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.dragEntered]: isDragEntered,
        [styles.disabled]: isDisabled,
      })}
      onClick={handleClick}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {isDragEntered ? (
        <Text className={styles.placeholder}>{dragEnteredPlaceholder}</Text>
      ) : (
        <DropZoneFileInfo
          fileName={fileName}
          disabled={isDisabled}
          isProcessing={isProcessing}
          shouldRenderDescription={shouldRenderDescription}
          onRemoveFile={handleRemoveFile}
        />
      )}
      <DropZoneMessage />
      {error && (
        <Alert
          banner
          type="error"
          className={styles.error}
          message={error}
          icon={<CustomIcon type={CustomIconType.Error} className={styles.errorIcon} />}
        />
      )}
    </div>
  )
}
