import { TabsProps } from 'antd'
import { TranscriptFilterTabs } from 'redux/actions/transcription-library/types'
import { intl } from 'utils/common/intl'

export const TRANSCRIPT_FILTER_TAB_ITEMS: TabsProps['items'] = [
  {
    key: TranscriptFilterTabs.Transcripts,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.table-filter-tabs.transcripts.label',
    }),
  },
  {
    key: TranscriptFilterTabs.Expiring,
    label: intl.formatMessage({
      id: 'component.audio-pipeline.table-filter-tabs.expiring.label',
    }),
  },
]
