import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/types/store'
import { Loaders } from 'redux/reducers/loaders/types'
import { LanguageSelectOptionIds } from 'components/audio-pipeline/FileUpload/LanguageSelect/types'
import { LANGUAGE_SELECT_OPTIONS } from 'components/audio-pipeline/FileUpload/LanguageSelect/constants'
import { Select } from 'components/common/data-entry/Select'
import { setSelectedLanguage } from 'redux/actions/audio-pipeline'
import { getSelectedLanguage } from 'redux/selectors/audio-pipeline'
import { getIsLoading } from 'redux/selectors/loaders'
import styles from './styles.module.css'
import { trackUserInteraction } from 'utils/tracking'
import { DataLayerTrackingEvents, ObserveEventInteractionMethod } from 'utils/tracking/constants'
import config from 'config'

export const LanguageSelect: React.FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) =>
    getIsLoading(state, Loaders.TranscriptionData)
  )
  const language = useSelector(getSelectedLanguage)
  const handleChange = useCallback(
    (value: LanguageSelectOptionIds) => {
      dispatch(setSelectedLanguage(value))

      trackUserInteraction({
        interactionTarget: DataLayerTrackingEvents.DropdownSelection,
        interactionMethod: ObserveEventInteractionMethod.Click,
        targetMetaData: {
          elementName: config.trackIds.languageSelect,
          elementValue: value,
        },
      })
    },
    [dispatch]
  )

  return (
    <Select
      disabled={isLoading}
      className={styles.wrapper}
      value={language}
      options={LANGUAGE_SELECT_OPTIONS}
      onChange={handleChange}
    />
  )
}
