import { MenuProps}  from 'antd'
import { Link } from 'react-router-dom'
import config from 'config'
import { intl } from 'utils/common/intl'
import { UserProfile } from 'components/layouts/common/Header/UserProfile'
import { LogoutButton } from 'components/common/auth/LogoutButton'
import styles from './styles.module.css'

export const MENU_ITEMS_TRIGGER: ("hover" | "contextMenu" | "click")[] = ['click']

export enum MenuOptions {
  Help = 'help',
  Profile = 'profile',
  Logout = 'logout',
  ReportAnIssue = 'reportAnIssue',
  Divider = 'divider'
}

export const getMenuItems = () :MenuProps['items'] => [
  {
    key:MenuOptions.Help,
    label: <Link
      to={config.links.newsTranscribeGuide}
      target="_blank"
      className={styles.helpAndSupport}
    >
      {intl.formatMessage({
        id: 'component.common.help-link.text',
      })}
    </Link>
  },
  {
    key: MenuOptions.ReportAnIssue,
    label: <Link
      to={config.links.reportAnIssue}
      target="_blank"
      className={styles.helpAndSupport}
    >
      {intl.formatMessage({
        id: 'component.common.report-issue.text'
      })}
    </Link>
  },
  {
    key: MenuOptions.Divider,
    type: 'divider',
  },
  {
    key: MenuOptions.Profile,
    label: <UserProfile className={styles.userProfile} hideIcon={true}/>
  },
  {
    key: MenuOptions.Logout,
    label: <LogoutButton className={styles.logoutButton} />
  }
]

