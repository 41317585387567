import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { NoData } from 'components/common/other/NoData'
import styles from './styles.module.css'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'

export interface Props {
  className?: string
}

export const NoExpiringTranscriptions: React.FC<Props> = ({ className }) => {
  const { formatMessage } = useIntl()
  const description = formatMessage({
    id: 'component.common.transcript-page.no-expiring.description',
  })
  const message = formatMessage({
    id: 'component.common.transcript-page.no-expiring.message',
  })

  return (
    <div className={classNames(styles.wrapper, className)}>
      <NoData
        shouldRenderDescription
        shouldRenderMessage
        message={description}
        description={message}
        className={styles.noData}
        icon={
          <CustomIcon
            className={styles.noDataIcon}
            type={CustomIconType.NoExpiringTranscriptsIcon}
          />
        }
      />
    </div>
  )
}
