import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { TranscriptionLibraryItemDataKeys } from 'redux/reducers/transcription-library/types'
import { format } from 'date-fns'
import { formatPlaybackWordTime } from 'utils/audio-pipeline/audio-player'
import styles from './styles.module.css'

interface Props {
  id: string
  value?: any
  disabled?: boolean
  className?: string
  formatter?: (payload: { id: string, value: any }) => string
}

export const TranscriptionTableCellValue: React.FC<Props> = ({
  id,
  value,
  disabled,
  className,
  formatter
}) => {
  const { formatMessage } = useIntl()
  const getFormattedValue = useCallback(() => {
    if (formatter) {
      return formatter({ id, value })
    }

    const isDate = id === TranscriptionLibraryItemDataKeys.CreatedAt
      || id === TranscriptionLibraryItemDataKeys.ExpireAt

    if (!value) {
      return formatMessage({
        id: 'component.transcription-library.table.column.no-data.label'
      })
    }

    if (id === TranscriptionLibraryItemDataKeys.Duration) {
      return formatPlaybackWordTime(value)
    }

    if (isDate) {
      return format(new Date(value), 'dd MMM yyyy HH:mm')
    }

    return value
  }, [id, value, formatMessage, formatter])

  return (
    <span
      className={classNames(className, styles.wrapper, styles[id], {
        [styles.disabled]: disabled
      })}
    >
      {getFormattedValue()}
    </span>
  )
}
