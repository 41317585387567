export const config = {
  dev: {
    api: {
      url: 'http://localhost:8085',
    },
    links: {
      newsTranscribeGuide: 'https://sites.google.com/news.co.uk/newsuk-howtoguides/home',
      reportAnIssue:
        'https://sofi.newscorp.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=6f7d50e987cc701092d696883cbb3530&searchTerm=broken',
    },
    auth: {
      enabled: true,
      oidc: {
        issuer: 'https://newscorp.oktapreview.com/oauth2/aus27dvbc31HFdgyN0h8',
        clientId: '0oa1ythmrv7xd7I1l0h8',
        scopes: ['openid', 'email'],
        redirectUri: `http://localhost:8000/user/login`,
        postLogoutRedirectUri: 'http://localhost:8000/user/login',
      },
    },
    uploadEnabled: true,
    trackIds: {
      userDepartment: 'user-department',
      transcribeButton: 'transcribe-button',
      openTranscription: 'open-transcription',
      transcribe: 'transcribe',
      uploadFile: 'upload-file',
      renameTranscription: 'rename-transcription',
      deleteTranscription: 'delete-transcription',
      languageSelect: 'language-select',
      replaceText: 'replace-text',
      replaceAllText: 'replace-all-text',
      searchText: 'search-text',
      copyTranscription: 'copy-transcription',
      editWord: 'edit-word',
      undoEditWord: 'undo-edit-word',
      generateSEOTags: 'generate-seo-tags',
      generateSummary: 'generate-summary',
      generateHeadline: 'generate-headline',
      generateDraftArticle: 'generate-draft-article',
      playPlayback: 'play-playback',
      pausePlayback: 'pause-playback',
      setPlaybackSpeed: 'set-playback-speed',
      transcribeGuideClick: 'transcribe-guide-click',
      reportAnIssueClick: 'report-an-issue-click',
      logout: 'logout',
    },
  },
  staging: {
    api: {
      url: 'https://news-transcribe.staging-news.co.uk/',
    },
    links: {
      newsTranscribeGuide: 'https://sites.google.com/news.co.uk/newsuk-howtoguides/home',
      reportAnIssue:
        'https://sofi.newscorp.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=6f7d50e987cc701092d696883cbb3530&searchTerm=broken',
    },
    auth: {
      enabled: true,
      oidc: {
        issuer: 'https://newscorp.oktapreview.com/oauth2/aus27s67k5ut0hXlM0h8',
        clientId: '0oa21yeaspuUudxBx0h8',
        scopes: ['openid', 'email'],
        redirectUri: `https://news-transcribe.staging-news.co.uk/user/login`,
        postLogoutRedirectUri: 'https://news-transcribe.staging-news.co.uk/user/login',
      },
    },
    uploadEnabled: true,
    trackIds: {
      userDepartment: 'user-department',
      transcribeButton: 'transcribe-button',
      openTranscription: 'open-transcription',
      transcribe: 'transcribe',
      uploadFile: 'upload-file',
      renameTranscription: 'rename-transcription',
      deleteTranscription: 'delete-transcription',
      languageSelect: 'language-select',
      replaceText: 'replace-text',
      replaceAllText: 'replace-all-text',
      searchText: 'search-text',
      copyTranscription: 'copy-transcription',
      editWord: 'edit-word',
      undoEditWord: 'undo-edit-word',
      generateSEOTags: 'generate-seo-tags',
      generateSummary: 'generate-summary',
      generateHeadline: 'generate-headline',
      generateDraftArticle: 'generate-draft-article',
      playPlayback: 'play-playback',
      pausePlayback: 'pause-playback',
      setPlaybackSpeed: 'set-playback-speed',
      transcribeGuideClick: 'transcribe-guide-click',
      reportAnIssueClick: 'report-an-issue-click',
      logout: 'logout',
    },
  },
  prod: {
    api: {
      url: 'https://news-transcribe.news.co.uk/',
    },
    links: {
      newsTranscribeGuide: 'https://sites.google.com/news.co.uk/newsuk-howtoguides/home',
      reportAnIssue:
        'https://sofi.newscorp.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=6f7d50e987cc701092d696883cbb3530&searchTerm=broken',
    },
    auth: {
      enabled: true,
      oidc: {
        issuer: 'https://newscorp.okta.com/oauth2/aus178doqpxBezm0q0x8',
        clientId: '0oa144lbo0zTWZMXl0x8',
        scopes: ['openid', 'email'],
        redirectUri: `https://news-transcribe.news.co.uk/user/login`,
        postLogoutRedirectUri: 'https://news-transcribe.news.co.uk/user/login',
      },
    },
    uploadEnabled: true,
    trackIds: {
      userDepartment: 'user-department',
      transcribeButton: 'transcribe-button',
      openTranscription: 'open-transcription',
      transcribe: 'transcribe',
      uploadFile: 'upload-file',
      renameTranscription: 'rename-transcription',
      deleteTranscription: 'delete-transcription',
      languageSelect: 'language-select',
      replaceText: 'replace-text',
      replaceAllText: 'replace-all-text',
      searchText: 'search-text',
      copyTranscription: 'copy-transcription',
      editWord: 'edit-word',
      undoEditWord: 'undo-edit-word',
      generateSEOTags: 'generate-seo-tags',
      generateSummary: 'generate-summary',
      generateHeadline: 'generate-headline',
      generateDraftArticle: 'generate-draft-article',
      playPlayback: 'play-playback',
      pausePlayback: 'pause-playback',
      setPlaybackSpeed: 'set-playback-speed',
      transcribeGuideClick: 'transcribe-guide-click',
      reportAnIssueClick: 'report-an-issue-click',
      logout: 'logout',
    },
  },
}
