import { createAction } from '@reduxjs/toolkit'
import {
  PrepareConsumeTranscriptions,
  PrepareFetchTranscription,
  PrepareUpdateTranscription,
  PrepareDeleteTranscriptionPayload,
  PrepareEditTranscription,
  PrepareConfirmEditTranscription,
  PrepareSetEditedTranscriptionId,
  UpdateTranscriptionPayload,
  PrepareSetTranscriptsActiveFilter,
} from 'redux/actions/transcription-library/types'
import {
  FETCH_TRANSCRIPTIONS,
  FETCH_TRANSCRIPTION,
  CONSUME_TRANSCRIPTIONS,
  START_POLLING_TRANSCRIPTIONS,
  STOP_POLLING_TRANSCRIPTIONS,
  UPDATE_TRANSCRIPTION,
  DELETE_TRANSCRIPTION,
  EDIT_TRANSCRIPTION,
  CONFIRM_DELETE_TRANSCRIPTION,
  CANCEL_DELETE_TRANSCRIPTION,
  CONFIRM_EDIT_TRANSCRIPTION,
  CANCEL_EDIT_TRANSCRIPTION,
  REMOVE_TRANSCRIPTION,
  SET_EDITED_TRANSCRIPTION_ID,
  SET_ACTIVE_FILTER,
} from 'redux/actions/transcription-library/constants'

export const fetchTranscriptions = createAction(FETCH_TRANSCRIPTIONS)
export const consumeTranscriptions = createAction<PrepareConsumeTranscriptions>(
  CONSUME_TRANSCRIPTIONS,
  (data) => ({
    payload: { data },
  })
)

export const fetchTranscription = createAction<PrepareFetchTranscription>(
  FETCH_TRANSCRIPTION,
  (id) => ({
    payload: { id },
  })
)

export const startPollingTranscriptions = createAction(START_POLLING_TRANSCRIPTIONS)

export const stopPollingTranscriptions = createAction(STOP_POLLING_TRANSCRIPTIONS)

export const updateTranscription = createAction<PrepareUpdateTranscription>(
  UPDATE_TRANSCRIPTION,
  ({ id, name }: UpdateTranscriptionPayload) => ({
    payload: { id, name },
  })
)

export const deleteTranscription = createAction<PrepareDeleteTranscriptionPayload>(
  DELETE_TRANSCRIPTION,
  (id) => ({
    payload: { id },
  })
)

export const confirmDeleteTranscription = createAction(CONFIRM_DELETE_TRANSCRIPTION)

export const cancelDeleteTranscription = createAction(CANCEL_DELETE_TRANSCRIPTION)

export const editTranscription = createAction<PrepareEditTranscription>(
  EDIT_TRANSCRIPTION,
  (id) => ({
    payload: { id },
  })
)

export const setEditedTranscriptionId = createAction<PrepareSetEditedTranscriptionId>(
  SET_EDITED_TRANSCRIPTION_ID,
  (id) => ({
    payload: { id },
  })
)

export const confirmEditTranscription = createAction<PrepareConfirmEditTranscription>(
  CONFIRM_EDIT_TRANSCRIPTION,
  (name: string) => ({
    payload: { name },
  })
)

export const cancelEditTranscription = createAction(CANCEL_EDIT_TRANSCRIPTION)

export const removeTranscription = createAction<PrepareDeleteTranscriptionPayload>(
  REMOVE_TRANSCRIPTION,
  (id) => ({
    payload: { id },
  })
)

export const setTranscriptsActiveFilter = createAction<PrepareSetTranscriptsActiveFilter>(
  SET_ACTIVE_FILTER,
  (key) => ({
    payload: { key },
  })
)
