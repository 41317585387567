import { TranscriptFilterTabs } from 'redux/actions/transcription-library/types'
import { TranscriptionLibraryStateType } from 'redux/reducers/transcription-library/types'

export const TRANSCRIPTION_EXPIRING_DAYS_THRESHOLD = 90
export const TRANSCRIPTION_EXPIRING_DAYS_LEFT_THRESHOLD = 14

export const TranscriptionLibraryInitialState: TranscriptionLibraryStateType = {
  editedItemId: null,
  activeFilter: TranscriptFilterTabs.Transcripts,
  itemsById: {},
}
